/* eslint-disable @next/next/no-img-element */
import Checkbox from "@/components/inputs/Checkbox";
import { AutoResizeTextArea } from "@/components/inputs/TextArea";
import TextField from "@/components/inputs/TextField";
import Modal, { ModalBody, ModalButton } from "@/components/modals/Modal";
import { useDevTools } from "@/hooks/useUIStore";
import { classNames } from "@/lib/utils";
import { EntityFact, FactType, PersonFact } from "@/models/facts/facts.types";
import { useEntityStore } from "@/stores/entityStore";
import { EntityType } from "@/types";
import { EyeIcon, PencilIcon } from "@heroicons/react/24/outline";
import { useStore } from "@nanostores/react";
import { useEffect, useState } from "react";

export default function FactEditModal() {
  const entityStore = useEntityStore();
  const modalProps = useStore(entityStore.showFactEditModal);
  const entity = useStore(entityStore.entity);
  const isYou = useStore(entityStore.isYou);
  const canEdit = useStore(entityStore.canEdit);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modalProps) {
      setError(null);
      setLoading(false);
    }
  }, [modalProps]);

  const open = !!modalProps;

  if (!open) return null;

  const { type, currentValue } = modalProps;

  const setOpen = (open: boolean) => {
    if (!open) entityStore.showFactEditModal.set(undefined);
  };

  const youTheyTheCompany =
    isYou ? "you"
    : entity.type == EntityType.Person ? "they"
    : "the company";
  const yourselfThemTheCompany =
    isYou ? "yourself"
    : entity.type == EntityType.Person ? "them"
    : "the company";
  const yourTheirTheCompanys =
    isYou ? "your"
    : entity.type == EntityType.Person ? "their"
    : "the company's";

  const modalClass = type == EntityFact.About ? "md:w-[900px]" : "";

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const input = form.querySelector("[name=input]") as HTMLInputElement;

    try {
      setError(null);
      setLoading(true);
      const value = convertAndValidate(type, input.value);
      await entityStore.updateFact(type, value);
      setOpen(false);
    } catch (error) {
      setError(String(error));
    } finally {
      setLoading(false);
    }
  };

  const buttons = (
    <>
      {loading && <div className="text-gray-400 text-sm">Saving...</div>}
      {error && <div className="text-red-500 text-sm">{error}</div>}
      {canEdit && (
        <ModalButton disabled={loading} className={"bg-blue-600 hover:bg-blue-800"}>
          Save
        </ModalButton>
      )}

      <ModalButton
        onClick={(e) => {
          e.preventDefault();
          setOpen(false);
        }}
        disabled={loading}
        className="bg-gray-600 hover:bg-gray-800"
      >
        {canEdit ? "Cancel" : "Done"}
      </ModalButton>
    </>
  );

  const factTitle = type == PersonFact.Birthyear ? "Age" : type;

  const title = `${canEdit ? "Editing" : "Viewing"} ${factTitle}`;
  const Icon = canEdit ? PencilIcon : EyeIcon;

  const body: React.JSX.Element = (() => {
    switch (type) {
      case PersonFact.Birthyear:
        return (
          <div>
            What year were {youTheyTheCompany} born?
            <TextField
              name="input"
              type="number"
              className="w-full mt-4"
              placeholder={"e.g. 1980"}
              defaultValue={currentValue}
              readOnly={!canEdit}
            />
          </div>
        );
      case PersonFact.Location:
        return (
          <div>
            What is {yourTheirTheCompanys} location?
            <TextField
              name="input"
              className="w-full mt-4"
              placeholder={"e.g. San Francisco, CA"}
              defaultValue={currentValue}
              readOnly={!canEdit}
            />
          </div>
        );
      case PersonFact.Pronouns:
        return (
          <div>
            What are {yourTheirTheCompanys} desired pronouns?
            <PronounSelector value={currentValue} />
          </div>
        );
      case EntityFact.About:
        return (
          <div>
            Write something about {yourselfThemTheCompany}:
            <AutoResizeTextArea
              name="input"
              className="w-full mt-4 min-h-[10rem]"
              defaultValue={currentValue}
              readOnly={!canEdit}
            />
          </div>
        );
      default:
        return (
          <div>
            What is {yourTheirTheCompanys} {type.toLowerCase()}?
            <TextField
              name="input"
              className="w-full mt-4"
              defaultValue={currentValue}
              readOnly={!canEdit}
            />
          </div>
        );
    }
  })();

  return (
    <Modal open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit}>
        <ModalBody
          title={title}
          icon={<Icon className={classNames("w-6 h-6 text-blue-600")} />}
          iconBg={"bg-blue-100"}
          buttons={buttons}
          className={modalClass}
        >
          {body}
        </ModalBody>
      </form>
    </Modal>
  );
}

function convertAndValidate(type: FactType, value: string) {
  if (type == PersonFact.Birthyear) {
    const year = parseInt(value);
    if (isNaN(year)) {
      throw new Error("Invalid year");
    }
    if (year < 1900 || year > new Date().getFullYear()) {
      throw new Error("Please enter a valid year");
    }
    return year;
  } else return value;
}

function PronounSelector({ value }: { value: string | undefined }) {
  const [selected, setSelected] = useState(value);
  const options = [
    { label: "He/Him", value: "he/him" },
    { label: "She/Her", value: "she/her" },
    { label: "They/Them", value: "they/them" },
    { label: "Unknown", value: "" },
  ];

  return (
    <>
      <input type="hidden" name="input" value={selected} />
      <ul className="flex flex-col gap-2 mt-2">
        {options.map((option) => (
          <li key={option.value}>
            <Checkbox
              id={`pronoun-${option.value.replace(/\//g, "-")}`}
              radio
              label={option.label}
              name="pronouns"
              value={option.value}
              defaultChecked={
                selected == option.value || (selected == undefined && option.value == value)
              }
              onChange={(e) => setSelected(e.target.value)}
            />
          </li>
        ))}
      </ul>
    </>
  );
}
