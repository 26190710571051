import { forwardRef, InputHTMLAttributes, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  id?: string;
  label?: string;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  radio?: boolean;
  description?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export default forwardRef<HTMLInputElement, PropsWithChildren<Props>>(
  function Checkbox(props, ref) {
    const {
      label,
      radio,
      className,
      inputClassName,
      labelClassName,
      description,
      children,
      ...rest
    } = props;
    return (
      <div className={twMerge("relative flex items-center", className)}>
        <input
          type={radio ? "radio" : "checkbox"}
          ref={ref}
          {...rest}
          className={twMerge(
            "h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600",
            inputClassName,
          )}
        />
        <label
          htmlFor={rest.id}
          className={twMerge(
            "ml-3 text-sm select-none",
            labelClassName,
            rest.id ? "cursor-pointer" : "",
          )}
        >
          {label && <span className="font-medium">{label}</span>}
          {description && <span className="text-gray-500"> {description}</span>}
          {children}
        </label>
      </div>
    );
  },
);
