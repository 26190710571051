import ProfileDevButtons from "@/components/entities/ProfileDevButtons";
import DogfoodEntityFeedback from "@/components/feedback/DogfoodEntityFeedback";
import Layout from "@/components/layout/Layout";
import PageLayout from "@/components/layout/PageLayout";
import FactEditModal from "@/components/modals/FactEditModal";
import EntityTOC from "@/components/sections/EntityTOC";
import ErrorBoundary from "@/components/ui/ErrorBoundary";
import { useDevTools, useUI } from "@/hooks/useUIStore";
import { EntityLayoutContext, getMainEntityStore } from "@/stores/entityStore";
import { EntityUIType, ServerProps } from "@/types";
import { useStore } from "@nanostores/react";
import EntityContent from "./EntityContent";

export default function EntityLayout(props: ServerProps) {
  useUI(props);
  const isDev = useDevTools();
  const mainEntityStore = getMainEntityStore();
  const entity = useStore(mainEntityStore.entity);

  return (
    <EntityLayoutContext.Provider value={EntityUIType.Main}>
      <Layout title={entity.name}>
        <PageLayout outerClassName="bg-gray-50">
          <div className="flex flex-col sm:flex-row gap-4">
            <div>
              <ErrorBoundary>
                <div className="sticky top-4 flex flex-col gap-4 max-h-[calc(100vh-6rem)] overflow-y-auto overflow-x-hidden no-scrollbar">
                  <EntityTOC />
                  {isDev && <ProfileDevButtons />}
                </div>
                {isDev && <DogfoodEntityFeedback visible showMinimizedIcon />}
              </ErrorBoundary>
            </div>

            <div className="flex flex-col flex-1 mb-20 overflow-x-hidden">
              <EntityContent />
            </div>
            <FactEditModal />
          </div>
        </PageLayout>
      </Layout>
    </EntityLayoutContext.Provider>
  );
}
