import API from "@/client/api";
import useSubmitButton from "@/components/hooks/useSubmitButton";
import Checkbox from "@/components/inputs/Checkbox";
import ComboBox from "@/components/inputs/ComboBox";
import TextArea, { AutoResizeTextArea } from "@/components/inputs/TextArea";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { useDevTools } from "@/hooks/useUIStore";
import { useEntityStore } from "@/stores/entityStore";
import { feedbackStore } from "@/stores/feedbackStore";
import { ProfileFeedback } from "@/types";
import { ChevronUpIcon, MinusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { FeedbackCategory } from "@prisma/client";
import { FormEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { toast } from "react-toastify";

const ratingOptions = [
  { id: "1", name: "1 - 💩 major issue(s)" },
  { id: "2", name: "2 - 🚩 incorrect information" },
  { id: "3", name: "3 - 😐 highlights issue" },
  { id: "4", name: "4 - ✨ solid profile" },
  { id: "5", name: "5 - 👑 awesome" },
];

type Props = {
  visible?: boolean;
  showMinimizedIcon?: boolean;
  issueCategoryOptions?: FeedbackCategory[];
  mode?: "reviewer" | "admin";
  onSubmit?: (updatedReview: ProfileFeedback) => void;
};

// feedback category ids
const p0Bug = "mvc5qa6ilrp0q6bwee6xn192";
const highlightsIssue = "fvreedjw07tzsn2q3rkrmlji";
const otherIssue = "cm2tm1odd0199xt5qf5d2tev1";
const goodStuff = "cm3q8lou003d16x6zz1lb5804";

/*
Can either use entityStore when pulling up component on a profile page,
OR parent component can pass in a feedback obj.
 */
const DogfoodEntityFeedback = forwardRef(
  (
    { visible, showMinimizedIcon, issueCategoryOptions, mode = "reviewer", onSubmit }: Props,
    ref,
  ) => {
    const [isVisible, setIsVisible] = useState(visible ?? false);

    // State from passed in props
    const [localFeedback, setLocalFeedback] = useState<ProfileFeedback | null>(null);
    const [localIssueCategoryOptions, setLocalIssueCategoryOptions] =
      useState(issueCategoryOptions);
    useImperativeHandle(ref, () => ({
      setFeedback: (data: ProfileFeedback) => {
        setLocalFeedback(data);
        setIsVisible(true);
      },
      setIsVisible: (isVisible: boolean) => {
        setIsVisible(isVisible);
      },
    }));

    const localSaveDogfoodFeedback = async (feedback: ProfileFeedback) => {
      if (!feedback) {
        throw new Error("Feedback is not set"); // TODO(rhwang)
      }
      return API.dogfoodFeedbacks.update(feedback.id, {
        response: {
          text: feedback.response.text,
          rating: feedback.response.rating,
        },
        feedbackCategories: feedback.feedbackCategories?.map((issueCategory) => {
          const fullCategory = issueCategories.find((category) => category.id === issueCategory.id);
          if (!fullCategory) {
            throw new Error(`Issue category with id ${issueCategory.id} not found`);
          }
          return fullCategory;
        }),
      });
    };

    // State from stores
    const storeFeedback = useStore(feedbackStore.dogfoodFeedback);
    const storeIssueCategories = useStore(feedbackStore.categories);

    // Use local state if passed in, otherwise use store
    const dogfoodFeedback = localFeedback ?? storeFeedback;
    const setDogfoodFeedback = localFeedback ? setLocalFeedback : feedbackStore.dogfoodFeedback.set;
    const saveDogfoodFeedback =
      localFeedback ?
        (feedback: ProfileFeedback) => localSaveDogfoodFeedback(feedback)
      : (feedback: ProfileFeedback) => feedbackStore.saveDogfoodFeedback();

    const issueCategories = localIssueCategoryOptions ?? storeIssueCategories;
    const [rating, setRating] = useState<{ id: string; name: string } | undefined>();
    const [selectedIssueCategories, setSelectedIssueCategories] = useState<string[]>([]);
    const [isFeedbackLatest, setIsFeedbackLatest] = useState<boolean>(true);
    const { submitting, SubmitButton, setSubmitting } = useSubmitButton();

    const impliedRating =
      selectedIssueCategories.includes(p0Bug) ? ratingOptions[0]
      : selectedIssueCategories.includes(otherIssue) ? ratingOptions[1]
      : selectedIssueCategories.includes(highlightsIssue) ? ratingOptions[2]
      : selectedIssueCategories.includes(goodStuff) ? ratingOptions[4]
      : ratingOptions[3];

    // --- effects ---

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
      if (textAreaRef.current) {
        textAreaRef.current.value = dogfoodFeedback?.response.text ?? "";
      }
      setRating(
        dogfoodFeedback &&
          ratingOptions.find((option) => option.id === String(dogfoodFeedback?.response.rating)),
      );
      setSelectedIssueCategories(dogfoodFeedback?.feedbackCategories?.map((c) => c.id) ?? []);
      setIsFeedbackLatest(dogfoodFeedback?.isLatest ?? true);

      const hideOnAdminList = mode === "admin" && dogfoodFeedback !== undefined;
      const noneOrStaleReview =
        dogfoodFeedback === undefined || dogfoodFeedback?.isLatest === false;
      const isProd = process.env.NODE_ENV === "production";
      const showForm =
        submitting ||
        hideOnAdminList ||
        (mode === "reviewer" && noneOrStaleReview && document.body.clientWidth >= 768 && isProd);
      setIsVisible(showForm);
    }, [dogfoodFeedback, issueCategories, submitting, mode]);

    const handleSubmit = async (e: FormEvent) => {
      e.preventDefault();
      const form = e.target as HTMLFormElement;
      const data = new FormData(form);
      const responseText = (data.get("response") as string) || "";

      setSubmitting(true);
      try {
        // @ts-expect-error TODO(rhwang): hacking...I want this to be a partial, ie id may be undefined
        const newDogfoodFeedback: ProfileFeedback = {
          ...dogfoodFeedback,
          response: {
            rating: Number(impliedRating.id),
            text: responseText,
          },
          feedbackCategories: selectedIssueCategories.map((id) => ({ id, name: "" })),
        };
        setDogfoodFeedback(newDogfoodFeedback);

        await saveDogfoodFeedback(newDogfoodFeedback);
        toast.success("Feedback recorded!", {
          autoClose: 2000,
          onClose: () => setIsVisible(false),
        });
        if (onSubmit) onSubmit(newDogfoodFeedback);
      } catch (error) {
        toast.error("Failed to record feedback.");
      } finally {
        setSubmitting(false);
      }
    };

    if (!useDevTools()) return;

    const toggleIssueCategory = (id: string) => {
      setSelectedIssueCategories((prev) => {
        if (prev.includes(id)) {
          return prev.filter((issueCategory) => issueCategory !== id);
        } else {
          return [...prev, id];
        }
      });
    };

    const formElement = (
      <div
        className={`fixed ${mode === "admin" ? "top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3" : "bottom-24 right-4 w-80"} bg-white shadow-lg rounded-lg p-4 z-10`}
      >
        <ProfileBubble title={mode === "admin" ? "Edit Feedback" : "Employee Feedback"}>
          <button
            onClick={() => setIsVisible(false)}
            className="absolute top-3 right-3 flex items-center p-2 bg-gray-100 hover:bg-gray-200 rounded-md"
          >
            {mode === "admin" ?
              <XMarkIcon className="h-4 w-4 text-gray-600" />
            : <MinusIcon className="h-4 w-4 text-gray-600" />}
          </button>
          {process.env.NODE_ENV !== "production" && (
            <h2 className="bg-yellow-100 border-l-2 border-yellow-500 text-yellow-700 p-2 mb-4">
              You are dogfooding in the {process.env.NODE_ENV} environment.
            </h2>
          )}
          <form onSubmit={handleSubmit}>
            {!isFeedbackLatest && (
              <div className="mb-4 text-red-600">This feedback is stale. Update it below!</div>
            )}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                What feedback do you have for this profile?
              </label>
              <AutoResizeTextArea
                name="response"
                defaultValue={dogfoodFeedback?.response.text || ""}
                className={`mt-1 block w-full ${mode === "admin" ? "min-h-[10rem]" : ""}`}
                ref={textAreaRef}
              />
            </div>
            <div className="mb-4 flex flex-col gap-2">
              <label className="block text-sm font-medium text-gray-700">
                What's going on here?
              </label>
              <Checkbox
                id="cb-p0"
                label="Major issue, fix ASAP"
                className="text-red-600"
                checked={selectedIssueCategories.includes(p0Bug)}
                onChange={() => toggleIssueCategory(p0Bug)}
              />
              <Checkbox
                id="cb-other"
                label="Inaccuracy / missing info"
                checked={selectedIssueCategories.includes(otherIssue)}
                onChange={() => toggleIssueCategory(otherIssue)}
              />
              <Checkbox
                id="cb-highlights"
                label="Highlights style issue"
                checked={selectedIssueCategories.includes(highlightsIssue)}
                onChange={() => toggleIssueCategory(highlightsIssue)}
              />

              <Checkbox
                id="cb-nice"
                label="Really good stuff"
                checked={selectedIssueCategories.includes(goodStuff)}
                onChange={() => toggleIssueCategory(goodStuff)}
              />
            </div>
            {rating && rating.id != impliedRating.id && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Prev Rating:</label>
                <div>{rating.name}</div>
              </div>
            )}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Implied Rating:</label>
              <div>{impliedRating.name}</div>
            </div>
            <SubmitButton type="submit" className="w-full">
              Submit
            </SubmitButton>
          </form>
        </ProfileBubble>
      </div>
    );
    const minimizedElement = (
      <div className="fixed bottom-0 right-4">
        <button
          onClick={() => setIsVisible(true)}
          className="flex items-center p-2 bg-gray-100 hover:bg-gray-200 rounded-md"
        >
          <ChevronUpIcon className="h-4 w-4 text-gray-600 mr-2" />
          🐶
        </button>
      </div>
    );
    return (
      isVisible ? formElement
      : showMinimizedIcon ? minimizedElement
      : null
    );
  },
);

DogfoodEntityFeedback.displayName = "DogfoodEntityFeedback";

export default DogfoodEntityFeedback;
