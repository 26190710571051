import Head from "next/head";
import { ReactNode } from "react";
import { ToastContainer } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { twMerge } from "tailwind-merge";

export default function Layout({
  title,
  children,
  className,
}: {
  title?: string;
  children: ReactNode;
  className?: string;
}) {
  return (
    <div className="flex w-full h-full relative">
      {title && (
        <Head>
          <title>{title}</title>
        </Head>
      )}
      <main className={twMerge("flex-1 overflow-x-hidden flex flex-col print:h-max", className)}>
        {children}
      </main>
      <Tooltip id="tooltip" className="hidden sm:block z-50" />
      <ToastContainer />
    </div>
  );
}
